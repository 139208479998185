<template>
    <div>
        <draggable
            v-bind="dragOptions"
            ref="Draggable"
            class="menu-loop"
            handle=".menu-handle"
            tag="ul"
            :list="items"
            :group="{ name: 'g1' }"
        >
            <li
                v-for="element in items"
                :key="`menu-item-${element.id}`"
            >
                <section class="panel menu-item">
                    <header>
                        <div class="title">
                            <i class="icon md-menu menu-handle" />
                            {{ element.text }}
                            <i
                                v-if="!element.active"
                                class="fz-sm color-gray-light mr5"
                            >
                                {{ `(${$t('general.status.inactive')})` }}
                            </i>
                        </div>
                        <div class="functions">
                            <a
                                href="#"
                                class="icon md-pencil-outline ml5 hover-color-blue"
                                @click.prevent="edit(element)"
                            />
                            <a
                                href="#"
                                class="icon md-delete-forever hover-color-red"
                                @click.prevent="$refs.DeleteModal.open({ id: element.id })"
                            />
                        </div>
                    </header>
                </section>

                <menu-loop
                    :items="element.children"
                    @edit="edit"
                    @remove="remove"
                />
            </li>
        </draggable>

        <y-delete-modal
            ref="DeleteModal"
            confirm-only
            :type="$t('menu-maker.itemType')"
            @confirm="remove"
        />
    </div>
</template>

<script>

    import Draggable from 'vuedraggable';
    import YDeleteModal from '@deps/DeleteModal';

    export default {

        name: 'MenuLoop',

        components: {
            Draggable,
            YDeleteModal,
        },

        props: {

            items: {
                required: true,
                type    : Array,
            },

        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },
        },

        methods: {

            /**
             * Edit item
             *
             * @param element
             */
            edit(element) {
                this.$emit('edit', element);
            },

            /**
             * Remove item
             *
             * @param element
             */
            remove(element) {
                this.$emit('remove', element);
            },
        },

    };
</script>
