<template>
    <div>
        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 121px )"
        >
            <div v-if="menu.title">
                <!-- PageHead -->
                <y-page-head
                    :title="$t('menu-maker.page_head', {title: menu.title})"
                    icon="md-menu"
                >
                    <template slot="actions">
                        <y-button
                            color="green"
                            loading-on="submitting-form"
                            wide
                            @click.prevent.native="submit(save)"
                        >
                            {{ $t('button.save') }}
                        </y-button>
                        <y-button
                            v-if="isDeveloper"
                            class="color-red"
                            @click.prevent.native="$refs.DeleteModal.open({ id: menu.id })"
                        >
                            {{ $t('button.trash') }}
                        </y-button>
                    </template>
                </y-page-head>

                <!-- Main Content -->
                <main id="menu-maker">
                    <y-row>
                        <y-col size="4">
                            <div class="menu-categories mb20">
                                <section class="panel">
                                    <header>
                                        <div class="title">
                                            {{ $t('menu-maker.add_item') }}
                                        </div>
                                    </header>
                                </section>

                                <section
                                    v-for="(title, slug) in categories"
                                    :key="slug"
                                    class="panel menu-category"
                                >
                                    <header>
                                        <div class="title color-gray">
                                            {{ title }}
                                        </div>
                                        <div class="functions">
                                            <y-button
                                                size="xs"
                                                class="color-blue"
                                                @click.native.prevent="openAddMenuItemModal(null)"
                                            >
                                                {{ $t('button.create') }}
                                            </y-button>
                                        </div>
                                    </header>
                                </section>
                            </div>
                        </y-col>

                        <y-col>
                            <section
                                id="menu-editor"
                                class="panel"
                            >
                                <header>
                                    <div class="title">
                                        {{ $t('menu-maker.manage_items') }}
                                    </div>
                                </header>

                                <article>
                                    <div v-if="model.menus && model.menus.length">
                                        <y-menu-loop
                                            :items="model.menus"
                                            @edit="openAddMenuItemModal"
                                            @remove="remove"
                                        />
                                    </div>

                                    <y-empty-state
                                        v-else
                                        class="mb0"
                                        height="300"
                                        :message="$t('menu-maker.empty_state')"
                                    />
                                </article>
                            </section>
                        </y-col>
                    </y-row>

                    <!-- Add new item to menu -->
                    <y-add-menu-item
                        ref="AddMenuItem"
                        :payload="menu.uploader"
                        @add="addItem"
                        @edit="edit"
                    />

                    <!-- Delete a menu -->
                    <y-delete-modal
                        ref="DeleteModal"
                        model="menu"
                        :type="$t('menu-maker.type')"
                        kind-of-delete="trash"
                        @done="goToList"
                    />
                </main>
            </div>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import GlobalMixin from '@/mixins/Global';

    import { YEmptyState } from '@deps/EmptyState';
    import YMenuLoop from '@/modules/menu-maker/components/Loop';
    import YAddMenuItem from '@/modules/menu-maker/components/AddMenuItem';
    import YDeleteModal from '@deps/DeleteModal';

    export default {
        name: 'MenuEditor',

        components: {
            YEmptyState,
            YMenuLoop,
            YAddMenuItem,
            YDeleteModal,
        },

        mixins: [PageMixin, FormMixin, GlobalMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('menus-list', this.$t('breadcrumbs.menu.list')),
                this.$bc(this.$t('breadcrumbs.menu.edit')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.menu.title,
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                flat: [],
                path: '',

                model: {
                    id   : null,
                    menus: [],
                },

                menu: {
                    slug: this.$route.params.slug,
                },

                categories: {
                    custom: this.$t('menu-maker.custom_link'),
                },
            };
        },

        methods: {

            /**
             * Fetch settings
             */
            fetch() {
                return this.$services.MenuMaker.menusGet({ slug: this.menu.slug }).then((response) => {
                    this.menu = {
                        id      : response.data.metadata.id,
                        title   : response.data.metadata.title,
                        locale  : response.data.metadata.locale,
                        uploader: response.data.metadata.uploader,
                    };
                    this.$set(this, 'model', {
                        id   : response.data.metadata.id,
                        menus: response.data.results.menus || [],
                    });
                }).catch((error) => {
                    this.handleError(error);
                    this.goToList();
                });
            },

            /**
             * Open add menu item modal
             *
             * @param element
             */
            openAddMenuItemModal(element = null) {
                const el = element ? { ...element } : null;
                this.$refs.AddMenuItem.open(el);
            },

            /**
             * Add item to menu
             *
             * @param item
             */
            addItem(item) {
                this.model.menus.push(item);
                this.$refs.AddMenuItem.close();
                this.$toast.success(this.$t('menu-maker.add_item_ok'));
            },

            /**
             * Save menu
             */
            save() {
                return this.$services.MenuMaker.menusSave(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('menu-maker.type') }));
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Redirect after delete
             */
            goToList() {
                this.$router.push({ name: 'menu-maker-list' });
            },

            /**
             * @inheritDoc
             */
            remove(data) {
                this.model.menus.map((item, i) => {
                    this.removeLoop(this.model.menus[i], this.model.menus, data.id, i, data);
                    return true;
                });
            },

            /**
             * @inheritDoc
             */
            removeLoop(obj, parent, id, i) {
                if (obj.id === id) {
                    parent.splice(i, 1);
                }
                if (obj && obj.children && obj.children.length > 0) {
                    for (let j = 0; j < obj.children.length; j += 1) {
                        this.removeLoop(obj.children[j], obj.children, id, j);
                    }
                }
            },

            /**
             * @inheritDoc
             */
            edit(data) {
                this.model.menus.map((item, i) => {
                    this.editLoop(this.model.menus[i], this.model.menus, data.id, i, data);
                    return true;
                });
                this.$toast.success(this.$t('menu-maker.save_item_ok'));
                this.$refs.AddMenuItem.close();
            },

            /**
             * @inheritDoc
             */
            editLoop(obj, parent, id, i, data) {
                if (obj.id === id) {
                    this.$set(parent, i, data);
                }
                if (obj && obj.children && obj.children.length > 0) {
                    for (let j = 0; j < obj.children.length; j += 1) {
                        this.editLoop(obj.children[j], obj.children, id, j, data);
                    }
                }
            },

        },
    };
</script>
