<template>
    <y-modal
        ref="modal"
        @close="reset"
    >
        <template slot="header">
            <div class="title">
                <template v-if="editing">
                    {{ $t('menu-maker.edit_item') }}
                </template>
                <template v-else>
                    {{ $t('menu-maker.add_item') }}
                </template>
            </div>
        </template>

        <template slot="body">
            <form>
                <y-form-field
                    v-model="model.text"
                    validation="'required'"
                    name="link_text"
                />

                <y-form-field
                    v-model="model.link"
                    v-validate.persist="'required'"
                    name="link"
                />

                <y-form-uploader
                    name="image"
                    small
                    full-model
                    :accepted-files="['jpg', 'jpeg', 'png', 'svg']"
                    :max-files="1"
                    :payload="payload"
                    :value="editing ? model.icon : []"
                    @success="file($event)"
                    @remove="remove($event)"
                />

                <y-form-field
                    v-model="model.external"
                    type="checkbox"
                    name="target_external"
                    :label="$t('fields.target_external')"
                />

                <y-form-field
                    v-model="model.active"
                    type="checkbox"
                    name="active"
                    :label="$t('fields.active')"
                />
            </form>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    :key="editing ? 'edit-button' : 'create-button'"
                    color="blue"
                    loading-on="submitting-form"
                    :disabled="!model.text"
                    @click.prevent.native="submit(save)"
                >
                    {{ editing ? $t('button.edit') : $t('button.add') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import FormMixin from '@/mixins/Form';
    import { YModal, YFormField, YFormUploader } from '@deps';

    export default {

        name: 'AddMenu',

        components: {
            YModal,
            YFormField,
            YFormUploader,
        },

        mixins: [FormMixin],

        props: {

            payload: String,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                editing: false,
                model  : {
                    id      : generateId(),
                    text    : '',
                    external: 0,
                    active  : 1,
                    children: [],
                },
            };
        },

        methods: {

            /**
             * Open create modal
             *
             * @param element
             */
            open(element = null) {
                if (element) {
                    this.model = element;
                    this.editing = true;
                }
                this.$refs.modal.open();
            },

            /**
             * Close crate modal
             */
            close() {
                this.$refs.modal.close();
            },

            /**
             * Reset modal form
             */
            reset() {
                this.disableSubmitting();
                this.editing = false;
                this.model = {
                    id      : generateId(),
                    text    : '',
                    external: 0,
                    active  : 1,
                    children: [],
                };
            },

            /**
             * Create new menu
             */
            save() {
                return new Promise(() => (this.editing ? this.$emit('edit', this.model) : this.$emit('add', this.model)));
            },

            /**
             * file
             *
             * @param file
             */
            file(file) {
                const icon = {
                    link     : file.link,
                    versions : file.versions,
                    mime_type: file.mime_type,
                };
                this.$set(this.model, 'icon', icon);
            },

            /**
             * Remove Icon
             */
            remove() {
                this.$set(this.model, 'icon', []);
            },

        },

    };
</script>
